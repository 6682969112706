import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { navigation } from "../../utils/constants";

const NavBar = () => {
  const [path, setPath] = useState("/");
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
    console.log(location.pathname);
  }, [location]);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg fast-header fixed-top">
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img
              src="/assets/images/logos/Vitaminer-logo.png"
              className="img-fluid logo"
              alt="Vitaminer"
            />
          </a>
          <div
            className="navbar-toggler fast-burger-menu btn-primary-fast"
            // data-bs-toggle="offcanvas"
            // data-bs-target="#offcanvasRight"
            // aria-controls="offcanvasRight"
            onClick={() => setShow(!show)}>
            <div className="d-flex align-items-center justify-content-center burger-menu">
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

          <div className="collapse navbar-collapse flex justify-content-end">
            <ul className="navbar-nav  mb-2 mb-lg-0">
              {navigation.map((nav) => (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${path === nav.link ? "active" : ""}`}
                    to={nav.link}
                    onClick={() => {
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}>
                    {nav.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <div
        className={`offcanvas offcanvas-end fast-offcanvas ${
          show ? "show" : ""
        }`}
        tabIndex={-1}
        id="offcanvasRight">
        <div className="offcanvas-header d-flex justify-content-end">
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="offcanvas"
            // aria-label="Close"
            onClick={() => setShow(false)}></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-unstyled fast-overlay-menu">
            {navigation.map((nav) => (
              <li>
                <Link
                  to={nav.link}
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    setShow(false);
                  }}>
                  {nav.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
