import React from "react";
import { Link } from "react-router-dom";
import { Info } from "../utils/constants";
import ContactForm from "../components/ContactForm.tsx/ContactForm";

const Contact = () => {
  return (
    <>
      <section className="banner about-us">
        <div className="container h-100 d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h2 className="banner-title">Contact Us</h2>
            <nav
              aria-label="breadcrumb"
              className="d-flex justify-content-center fast-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa-solid fa-house"></i> Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="fast-contact-wrapper-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="about-us-title-sm">Let's Talk</div>
              <h3 className="about-us-title">
                Contact us for more information
              </h3>
            </div>
          </div>

          <div className="row fast-contact-form">
            <div className="col-lg-4">
              <ul className="list-unstyled">
                <li>
                  <div className="d-flex align-items-center">
                    <div className="contact-card">
                      <div className="contact-icon-card d-flex align-items-center justify-content-center">
                        <i className="icon icon-headset"></i>
                      </div>
                    </div>
                    <div className="contact-card-content">
                      <h3>Location</h3>
                      <p className="mb-0">{Info.Location} </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="contact-card">
                      <div className="contact-icon-card d-flex align-items-center justify-content-center">
                        <i className="icon icon-location"></i>
                      </div>
                    </div>
                    <div className="contact-card-content fast-support-phone">
                      <h3>Support</h3>
                      <p className="mb-0">
                        <a
                          href={`tel:${Info.Phone}`}
                          className="fast-support-phone">
                          {Info.Phone}
                        </a>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="contact-card">
                      <div className="contact-icon-card d-flex align-items-center justify-content-center">
                        <i className="icon icon-email"></i>
                      </div>
                    </div>
                    <div className="contact-card-content fast-support-phone">
                      <h3>Email</h3>
                      <p className="mb-0">
                        <a
                          href={`mailto:${Info.Email}`}
                          className="fast-support-phone">
                          {Info.Email}
                        </a>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ContactForm />
          </div>
        </div>
      </section>

      <div className="fast-contact-map pb-0">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33913.65968199835!2d35.483682746473086!3d33.889252211055286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17215880a78f%3A0x729182bae99836b4!2sBeirut!5e1!3m2!1sen!2slb!4v1729360214956!5m2!1sen!2slb"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
};

export default Contact;
