import React, { useState } from "react";
import { fetchApi } from "../../utils/connections";

const ContactForm = () => {
  const [form, setForm] = useState({
    name: "",
    to: "",
    phone: "",
    message: "",
    subject: "",
  });
  const [confirmation, setConfirmation] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const response = await fetchApi("send-email-vitaminer", "POST", form);
    if (!response.error) {
      setForm({
        name: "",
        to: "",
        phone: "",
        message: "",
        subject: "",
      });
      setConfirmation("Message sent successfully");
    } else {
      setConfirmation("Message not sent");
    }
    setLoading(false);
  };

  return (
    <form className="col-lg-8" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <input
            className="form-control fast-contact-form-input"
            type="text"
            placeholder="Full Name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
          />
        </div>
        <div className="col-lg-6">
          <input
            className="form-control fast-contact-form-input"
            type="email"
            name="email"
            placeholder="Email"
            value={form.to}
            onChange={(e) => setForm({ ...form, to: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <input
            className="form-control fast-contact-form-input"
            type="tel"
            placeholder="Your Phone"
            value={form.phone}
            onChange={(e) => setForm({ ...form, phone: e.target.value })}
            required
          />
        </div>
        <div className="col-lg-6">
          <input
            className="form-control fast-contact-form-input"
            type="text"
            placeholder="Subject"
            value={form.subject}
            onChange={(e) => setForm({ ...form, subject: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <textarea
            className="form-control fast-contact-form-input"
            placeholder="Write Message"
            id="exampleFormControlTextarea1"
            rows={3}
            value={form.message}
            onChange={(e) => setForm({ ...form, message: e.target.value })}
            required></textarea>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex flex-column align-items-center">
          <button
            className="btn btn-primary-fast"
            type="submit"
            disabled={loading}>
            {loading ? "Sending..." : "Send Message"}
          </button>
          {confirmation !== "" && (
            <div
              className={`mt-2 ${
                confirmation === "Message sent successfully"
                  ? "alert alert-success"
                  : "alert alert-danger"
              }`}>
              {confirmation}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
