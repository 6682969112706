import React, { useState } from "react";
import { Link } from "react-router-dom";

const About = () => {
  const [about, setAbout] = useState<"Mission" | "Vision">("Mission");
  const team = [
    {
      name: "Labib Nassif",
      bio: "Enthusiast and eager to learn chemical engineering student. I pursue knowledge in different aspects of engineering ranging from energy, petroleum and environment to water and waste treatment, pharmaceuticals and cosmetics. Made efforts to develop a set of skills that may be summarized in: Technical, communication, entrepreneurial, innovation, business, marketing skills. I Believe in the statement “He who says he can, and he who says he can’t are both usually right”.",
      image: "",
    },
    {
      name: "Nour Matta",
      bio: "I am a dedicated chemical engineering student with a deep interest in exploring and solving complex challenges through the principles of chemistry and engineering. My academic focus spans key areas such as process design, thermodynamics, reaction engineering, and materials science, where I am driven to develop innovative and efficient solutions to real-world problems, where I can apply my technical knowledge to advance technologies and drive positive change.",
      image: "",
    },
    {
      name: "Lea Nassif",
      bio: "Research enthusiast, perseverant independent woman, final-year medical student who learnt the value of work by working hard! My objective resides in applying my skills amidst challenges to become the best version of my dedicated, and ambitious self. Alongside my medical training, I have developed skills in leadership, problem-solving, and communication through clinical rotations, research, and volunteering. With a strong foundation in biology, I am passionate about interdisciplinary collaboration and eager to apply my scientific and clinical knowledge to innovative projects like the extraction of vitamin B3 from waste, bringing a methodical and research-driven approach to the team.",
      image: "",
    },
  ];
  return (
    <>
      <section className="banner about-us">
        <div className="container h-100 d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <h2 className="banner-title">About Vitaminer</h2>
              <nav
                aria-label="breadcrumb"
                className="d-flex justify-content-center fast-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>
                      <i className="fa-solid fa-house"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="fast-about-tile-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-3">
              <h2 className="about-us-title">About Vitaminer</h2>
              <p className="pb-3 text-justify">
                Welcome to Vitaminer, a tech-based startup aiming to valorize
                the tobacco industry’s waste. Making from waste a vital
                resource, we transform the tobacco industry’s waste into a
                valuable resource by producing high-quality Vitamin B3 (niacin)
                through cutting-edge bioconversion technology. By repurposing
                tobacco by-products, we not only reduce environmental impact but
                also create a vital nutrient used in pharmaceuticals, dietary
                supplements, cosmetics, and animal nutrition. This is where
                innovation meets sustainability.
              </p>
              <p className="pb-3 text-justify">
                It all started when we participated in the third batch of the
                IdeaLab program hosted by ACIE USEK in North Mount Lebanon in
                collaboration with Berytech and co-funded by the Embassy of the
                Netherlands in Lebanon
              </p>
              <p className="pb-3 text-justify">
                Our mission is simple: to harness the power of circular economy
                practices, turning waste into wellness. Join us in building a
                future where industries collaborate with nature, creating
                solutions that benefit both human health and the planet.
                <br />
              </p>
              <p className="pb-3 text-justify h3">
                Our final aim is “to make an impact”
              </p>
              {/* <a href="#" className="btn btn-primary-fast">
                Read More
              </a> */}
            </div>
            <div className="col-lg-4">
              <div className="fast-tile-block-img-wrapper">
                <img
                  src="/assets/images/logos/Vitaminer-small-light-logo.png"
                  className="img-fluid"
                  alt="Fast burner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fast-about-us-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <div>
                <div className="about-us-title-sm">{about}</div>
                <h3 className="about-us-title">
                  Our <span>{about}</span>
                </h3>
                <div className="d-flex pb-3">
                  <div>
                    <button
                      className="btn btn-primary-fast me-3"
                      onClick={() => {
                        setAbout("Mission");
                      }}
                      disabled={about === "Mission"}>
                      Our Mission
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary-fast"
                      onClick={() => {
                        setAbout("Vision");
                      }}
                      disabled={about === "Vision"}>
                      Our Vision
                    </button>
                  </div>
                </div>

                {about === "Mission" ? (
                  <p>
                    The idea involves a new concept that we aim to make feasible
                    through scientific and technological means.
                    <br /> Our mission at Vitaminer is to harness the power of
                    biotechnology to create sustainable, high-quality vitamin B3
                    from tobacco waste. We aim to reduce environmental waste,
                    promote human health, and lead the way in innovative,
                    eco-friendly vitamin production. By transforming byproducts
                    into valuable resources, we are committed to delivering
                    ethical, science-driven solutions that contribute to both
                    individual well-being and global sustainability.
                  </p>
                ) : (
                  <p>
                    We envision a world where waste is repurposed into
                    life-enhancing products, driving a circular economy that
                    benefits both people and the planet. Through our pioneering
                    approach to vitamin production, we strive to set a new
                    standard in sustainability, health innovation, and
                    environmental stewardship, inspiring industries across the
                    globe to embrace greener, more efficient practices for a
                    healthier, more sustainable future.
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="fast-about-block-img-wrapper">
                <img
                  src="/assets/images/img/DALL·E 2024-10-19 00.58.39 - An image symbolizing scientific and technological innovation in biotechnology for sustainability. The scene should include abstract elements represent.webp"
                  className="img-fluid rounded-circle pb-3"
                  alt="Fast burner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fast-benefits pb-2">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12 text-center">
              <h3 className="about-us-title">
                Our <span>Team</span>
              </h3>
            </div>
          </div>
          <div className="row fast-benefits-features-wrapper">
            {team.map((t, index) => (
              <div className="col-lg-4" key={index}>
                <div className="fast-benefits-card h-100">
                  <img
                    className="p-2 w-full "
                    style={{
                      aspectRatio: "1/1",
                    }}
                    src={
                      t.image !== ""
                        ? t.image
                        : "/assets/images/img/placeholder.png"
                    }
                    alt=""
                  />
                  <h3 className="fast-benefits-card-text pt-3">{t.name}</h3>
                  <p className="text-justify">
                    {t.bio !== "" ? t.bio : "No bio available"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
