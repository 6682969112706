const Home = () => {
  const industries = [
    {
      title: "Pharmaceutical industry",
      icon: "/assets/images/img/1.png",
    },
    {
      title: "Animal feed sector",
      icon: "/assets/images/img/2.png",
    },
    {
      title: "Nutraceutical companies",
      icon: "/assets/images/img/3.png",
    },
    {
      title: "Personal care and cosmetics industry",
      icon: "/assets/images/img/4.png",
    },
    {
      title: "Pet food products",
      icon: "/assets/images/img/5.png",
    },
    {
      title: "Dietary supplements and nutrition products",
      icon: "/assets/images/img/6.png",
    },
    {
      title: "Energy drinks and health beverages",
      icon: "/assets/images/img/7.png",
    },
    {
      title: "Fortified ingredients for breakfast cereals and beverages",
      icon: "/assets/images/img/8.png",
    },
    {
      title: "Nutritional components in infant formula production",
      icon: "/assets/images/img/9.png",
    },
  ];
  return (
    <>
      <section className="banner about-us">
        <div className="container h-100 d-flex align-items-center justify-content-center">
          <h2 className="banner-title text-center">
            <div>
              Sustainable <span>Vitamin B3</span>
            </div>
            <div className="h1 text-center">
              Innovating Health from Tobacco Waste
            </div>
          </h2>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src="/assets/images/logos/Vitaminer_small-dark-logo.png"
                className="img-fluid"
                alt="Vitaminer"
              />
            </div>
            <div className="col-md-6">
              <div className="about-us-title-sm">About us</div>
              <h3 className="about-us-title">
                Welcome to <span>Vitaminer</span>
              </h3>

              <p className="text-justify">
                A tech-based startup aiming to valorize the tobacco industry’s
                waste. Making from waste a vital resource, we transform the
                tobacco industry’s waste into a valuable resource by producing
                high-quality Vitamin B3 (niacin) through cutting-edge
                bioconversion technology.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="fast-featuers">
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="features-title">
                Call for <span>action</span>
              </h2>
            </div>
            <div className="col-lg-12 pb-5">
              <p className="features-text">
                Join us on the path to innovation and sustainability! Discover
                how Vitaminer is revolutionizing vitamin production by
                transforming waste into wellness. Whether you're looking to
                enhance your health, support sustainable practices, or explore
                partnership opportunities, now is the time to make a difference.
                Get in touch today and be a part of a healthier, greener future
                with Vitaminer.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="fast-working">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 text-center">
              <h3 className="about-us-title">
                Our <span>Products</span>
              </h3>
              <p>
                At Vitaminer, we offer two vital forms of vitamin B3 <br />
                <strong style={{ fontSize: "1.3rem" }}>
                  — Niacin and Niacinamide —
                </strong>{" "}
                <br />
                Both produced sustainably from repurposed tobacco waste, without
                compromising on quality or purity. These essential nutrients
                play a critical role in overall well-being, supporting
                everything from energy production to skin health.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="fast-benefits">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 text-center">
              <h3 className="about-us-title">Niacin (Vitamin B3) </h3>
              <p>
                Niacin, also known as nicotinic acid, is an indispensable
                nutrient that aids in converting food into energy by supporting
                cellular metabolism. It is renowned for promoting cardiovascular
                health by helping to manage cholesterol levels.
              </p>
            </div>
          </div>
          <div className="row fast-benefits-features-wrapper">
            <div className="col-lg-4">
              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">01</div>
                <h3 className="fast-benefits-card-text">
                  Included in multivitamin supplements to help lower LDL (bad)
                  cholesterol levels and boost HDL (good) cholesterol,
                  supporting heart health.
                </h3>
              </div>

              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">02</div>
                <h3 className="fast-benefits-card-text">
                  Utilized as a food premix to fortify cereals and flour with
                  essential nutrients, enhancing their nutritional value.
                </h3>
              </div>
            </div>
            <div className="col-lg-4 text-center d-flex align-items-center">
              <img
                src="/assets/images/img/Niacin (Vitamin B3) .png"
                className="img-fluid py-5"
                alt="Fast burner"
              />
            </div>
            <div className="col-lg-4">
              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">03</div>
                <h3 className="fast-benefits-card-text">
                  Serves as a crucial ingredient in feed premixes, contributing
                  to the balanced nutrition of livestock and animals.
                </h3>
              </div>

              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">04</div>
                <h3 className="fast-benefits-card-text">
                  Niacin feed grade is extensively incorporated in the pet food
                  industry to promote the health and well-being of pets.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fast-benefits">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 text-center">
              <h3 className="about-us-title">Niacinamide (Vitamin B3)</h3>
              <p>
                Niacinamide, a more gentle and versatile form of vitamin B3, is
                celebrated for its wide range of benefits, particularly in
                skincare and wellness. Known for its soothing, anti-inflammatory
                properties, niacinamide is a key ingredient in formulations
                designed to improve skin texture, reduce redness, and promote
                hydration. Beyond skincare, it also supports brain function and
                cellular repair, making it a valuable addition to daily health
                routines.
              </p>
            </div>
          </div>
          <div className="row fast-benefits-features-wrapper">
            <div className="col-lg-4">
              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">01</div>
                <h3 className="fast-benefits-card-text">
                  Niacinamide used in skincare products for whitening,
                  conditioning lotions, moisturizers, anti-aging, and anti-acne
                  creams.
                </h3>
              </div>

              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">02</div>
                <h3 className="fast-benefits-card-text">
                  Multivitamin tablets used as dietary supplements.
                </h3>
              </div>
            </div>
            <div className="col-lg-4 text-center d-flex align-items-center">
              <img
                src="/assets/images/img/Niacinamide (Vitamin B3).png"
                className="img-fluid py-5"
                alt="Fast burner"
              />
            </div>
            <div className="col-lg-4">
              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">03</div>
                <h3 className="fast-benefits-card-text">
                  Vitamin supplement and food premix included in health and
                  sports drinks for added benefits.
                </h3>
              </div>

              <div className="fast-benefits-card d-flex align-items-center">
                <div className="fast-benefits-card-green">04</div>
                <h3 className="fast-benefits-card-text">
                  Niacinamide formulated for products aimed at controlling hair
                  loss.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fast-featuers">
        <div className="container pb-5">
          <div className="">
            <h2 className="features-title">
              Partnership <span>invite</span>
            </h2>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-lg-8 col-12 pb-5">
              <p className="features-text text-justify">
                The idea has been created recently by students at the holy
                spirit university of kaslik Usek – Lebanon, in collaboration
                with the Asher center for innovation and entrepreneurship.
                <br />
                <br /> At Vitaminer, we are at the forefront of innovation,
                working diligently on groundbreaking research to revolutionize
                the production of vitamin B3 from tobacco waste. <br />
                <br /> While this exciting concept is still under research and
                development, we are confident in its potential to create a
                significant environmental and health impact.
                <br />
                <br /> By shaping a new tobacco waste management system, we are
                repurposing tobacco waste into valuable health products. We are
                not only driving environmental impact but also shaping the
                future of a sector.
                <br />
                <br /> We invite forward-thinking partners to collaborate with
                us on this journey, contributing expertise and resources to help
                bring this innovative idea to life.
                <br />
                <br /> Whether you're in the health, biotechnology,
                sustainability sector, or a vitamin manufacturer, partnering
                with Vitaminer offers a unique opportunity to align with a
                forward-thinking company dedicated to creating a healthier
                planet and enhancing human well-being. Together, we can turn
                waste into innovation and build a more sustainable future. Let’s
                embark on this transformative journey together.
                <br /> <br />
                For more collaborations, don’t hesitate to contact us.
              </p>
            </div>
            <div className="col-lg-4 col-12 px-5 ">
              <img
                src="/assets/images/img/invite.jpg"
                alt=""
                className="w-100"></img>
            </div>
          </div>
        </div>
      </section>

      <section className="fast-working">
        <div className="container">
          <div className="row ">
            <div className="col-lg-5">
              <img
                src="/assets/images/img/sustainable.png"
                className="img-fluid rounded-5"
                alt=""
              />
            </div>
            <div className="col-lg-7 ">
              <h3 className="about-us-title">
                <span>Sustainable and environmental</span> approach
              </h3>
              <p className="text-justify">
                The environmental impact of Vitaminer's innovative approach is
                profound. By repurposing tobacco waste to produce vitamin B3, we
                significantly reduce the amount of waste that would otherwise
                end up in landfills or contribute to environmental pollution.
                This process helps lower greenhouse gas emissions and promotes a
                circular economy by turning industrial byproducts into valuable
                resources. Additionally, our sustainable production methods
                reduce the demand for raw materials, conserving natural
                resources and minimizing ecological footprints. At Vitaminer, we
                are committed to creating health solutions that benefit both
                people and the planet, driving positive environmental change
                through innovation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="fast-benefits mb-2">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 text-center">
              <h3 className="about-us-title">
                <span>Industries </span>we serve
              </h3>
            </div>
          </div>
          <div className="row fast-benefits-features-wrapper">
            {industries.map((ind, index) => (
              <div key={index} className="col-lg-4 p-2">
                <div className="fast-benefits-card d-flex align-items-center h-100">
                  <img
                    className="p-2"
                    style={{
                      height: "auto",
                      width: "60px",
                      // borderRadius: "50%",
                      // objectFit: "cover",
                    }}
                    src={ind.icon}
                    alt=""
                  />
                  <h3 className="fast-benefits-card-text">{ind.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
