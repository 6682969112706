export const Info = {
  Title: "Vitaminer",
  LogoImage: "",
  Email: "vitaminerb3@hotmail.com",
  Phone: "+961 81 808 468",
  Location: "Beirut, Lebanon",
  SocialMedia: [
    {
      Icon: "",
      Link: "",
    },
  ],
};

export const navigation = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Contact",
    link: "/contact-us",
  },
];
