import React from "react";
import { Link } from "react-router-dom";
import { Info, navigation } from "../../utils/constants";

const Footer = () => {
  return (
    <footer className="fast-footer">
      <div className="container">
        <div className="row footer-second">
          <div className="col-lg-6">
            <h2 className="fast-footer-title">Links</h2>
            <ul className="fast-footer-menu">
              {navigation.map((nav) => (
                <li>
                  <Link
                    to={nav.link}
                    onClick={() => {
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}>
                    {nav.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-6">
            <img
              src="/assets/images/logos/Vitaminer-logo.png"
              className="img-fluid footer-logo"
              alt="Fast Burner"
            />
            <div className="d-flex align-items-center justify-content-start">
              <div className="fast-support-icon">
                <i className="icon icon-support"></i>
              </div>
              <div className="fast-support-wrapper">
                <div className="fast-support-title">Got Question?</div>
                <div className="fast-support-phone">
                  <a href={`tel:${Info.Phone}`}>{Info.Phone}</a>
                </div>
              </div>
            </div>
            <div className="fast-contact-wrapper">
              <div className="fast-contact-title">Contact Info</div>
              <div className="d-flex align-items-center justify-content-start">
                <div className="fast-contact-icon">
                  <i className="icon icon-geo"></i>
                </div>
                <div className="fast-contact-address">{Info.Location}</div>
              </div>
              <div className="fast-support-phone mt-2 d-flex gap-2">
                <i className="icon icon-email"></i>
                <a href={`mailto:${Info.Email}`} className="fast-contact-email">
                  {Info.Email}
                </a>
              </div>
            </div>
            <ul className="list-inline fast-social-icon-wrapper">
              {Info.SocialMedia.map((social) => (
                <li className="list-inline-item">
                  <a href={social.Link}>
                    <i className={`icon ${social.Icon}`}></i>
                  </a>
                </li>
              ))}
              {/* <li className="list-inline-item">
                <a href="/">
                  <i className="icon icon-twitter"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="icon icon-pinterest"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="icon icon-youtube"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="icon icon-insta"></i>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="signup-wrapper rounded-0 m-0 p-4 h-auto">
        <div className="d-flex text-white justify-content-center">
          © Copyright Vitaminer. All Rights Reserved.
        </div>
        <div className="d-flex text-white justify-content-center">
          Powered by{" "}
          <a href="https://asynk.tech" className="px-1">
            Asynk Tech
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
