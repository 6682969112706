"use server";
const PROD_FETCH_URL = "https://elite-heights-ad35e3c32668.herokuapp.com/";

export const fetchApi = async (
  path: string,
  method: string,
  body: any
): Promise<any> => {
  // const authorization = await getLocalData("token");

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  // if (authorization) headers.Authorization = authorization;

  const options: { method: string; headers: any; body?: string } = {
    method: method,
    headers: headers,
  };

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    options.body = JSON.stringify(body);
    console.log("options", options);
  }

  // console.warn(`${PROD_FETCH_URL}${path}`, options);

  try {
    console.log("path", `${PROD_FETCH_URL}${path}`);
    const response = await fetch(`${PROD_FETCH_URL}${path}`, options);

    const json = await response.json();
    console.warn(json);
    if (json.StatusCode === 403) {
    }
    console.log("json", json);
    return json;
  } catch (e) {
    // console.warn(e);
    if (e instanceof Error) {
      throw e; // or throw new Error(e.message);
    } else {
      throw new Error(String(e));
    }
  }
};
